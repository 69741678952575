(() => {
  document.addEventListener('DOMContentLoaded', () => {
    const switchEls = document.getElementsByName('event-switch');
    const elsContainer = document.querySelector('.cards-group__cards');
    const eventsData = [
      {
        img: "img/event-card.f79289c2.webp",
        date: "24.06.2021",
        description: "ИТ-ГРАД поможет бизнесу в Казахстане управлять данными любого объема в облачном хранилище"
      },
      {
        img: "img/event-card.f79289c2.webp",
        date: "18.03.2021",
        description: "Эксперты ИТ-ГРАД и Oblako.kz выбрали лучшие проекты студентов Казахстана"
      },
      {
        img: "img/event-card.f79289c2.webp",
        date: "09.09.2020",
        description: "Частное облако — полный контроль над своей виртуальной инфраструктурой"
      }
    ];
    const newsData = [
      {
        img: "img/event-card.f79289c2.webp",
        date: "04.08.2023",
        description: "Все больше компаний переходят на использование корпоративного облака для хранения и обработки своих данных. Корпоративное облако - это современная технология, которая позволяет организациям создавать виртуальные рабочие среды, доступные с любого устройства и из любой точки мира."
      },
      {
        img: "img/event-card.f79289c2.webp",
        date: "19.03.2023",
        description: "Аренда серверного оборудования с каждым годом становится всё более популярной. Создание оптимальной IT-инфраструктуры, точечный выбор конфигурации оборудования, отличное качество сервиса, великолепная защита, объединение физических, облачных виртуальных серверов — всё это сделало услугу одной из ключевых в IT-индустрии."
      },
      {
        img: "img/event-card.f79289c2.webp",
        date: "03.03.2023",
        description: "В этой обзорной статье расскажем о том, для каких задач подходит выделенный сервер, какими преимуществами обладает перед VPS и как его выбрать."
      }
    ];
    const template = (data) => `<a class="event-card" href=${data.link} alt="Not found"><div class="event-card__img"><img src=${data.img}></div><div class="event-card__info"><span class="event-card__date text_2">${data.date}</span><span class="event-card__label text_2">${data.description}</span></div></a>`;

    switchEls.forEach((switchEl) => {
      switchEl.addEventListener('change', (e) => {
        if (e.target.value === 'Новости') {
          let markup = '';

          newsData.forEach((data) => {
            markup += template(data)
          })

          elsContainer.innerHTML = markup;
        } else {
          let markup = '';

          eventsData.forEach((data) => {
            markup += template(data)
          })

          elsContainer.innerHTML = markup;
        }
      })
    })
  });
})()

